<template>
    <div>
        <b-button variant="primary" v-if="manage" v-b-modal.add-ads-management-modal>
            <span class="text-nowrap">Add New</span>
        </b-button>
        <b-modal id="add-ads-management-modal" hide-footer size="md" centered title="Add New" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{ errorMsg }}</b-alert>

                <p class="mb-0 ">Advertiser</p>
                <v-select
                    v-model="record.ads_account_id"
                    :clearable="false"
                    label="advertiser_name"
                    :options="get_ads_account"
                    :reduce="option => option._id"
                    placeholder="Select Advertiser"
                    class="mb-1"
                />

                <p class="mb-0 ">Title</p>
                <b-form-input class="mb-1" v-model="record.title" placeholder="Title" type="text" />

                <p class="mb-0 ">Ad Type</p>
                <v-select
                    v-model="record.ad_type"
                    :clearable="true"
                    label="label"
                    :options="addTypes"
                    :reduce="option => option.name"
                    placeholder="Select Ad Type"
                    class="mb-1"
                />

                <template v-if="record.ad_type == 'video'">
                    <b-button variant="primary" :disabled="loadingMedia || !record.ad_type" @click="$refs.refInputE2.click()">
                        <b-spinner v-if="loadingMedia" class="mr-1" small />
                        <input
                            ref="refInputE2"
                            type="file"
                            accept="video/mp4"
                            class="d-none"
                            @input="videoUpload"
                        />
                        <span class="d-none d-sm-inline">Upload Video</span>
                        <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                    </b-button>
                </template>
                <template v-else>
                    <b-button variant="primary" :disabled="loadingMedia || !record.ad_type" @click="$refs.refInputEl.click()">
                        <b-spinner v-if="loadingMedia" class="mr-1" small />
                        <input
                            ref="refInputEl"
                            type="file"
                            accept="image/x-png,image/gif,image/jpeg"
                            class="d-none"
                            multiple
                            @input="ImageRendererItems"
                        />
                        <span class="d-none d-sm-inline">Upload Image</span>
                        <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                    </b-button>
                </template>

                <div class="mt-1 w-50">
                    <template v-if="record.ad_type == 'video'">
                        <b-embed
                            v-if="record.ad_media"
                            type="video"
                            aspect="4by3"
                            controls
                        >
                            <source :src="record.ad_media" type="video/mp4" />
                        </b-embed>
                    </template>
                    <template v-else>
                        <img v-if="record.ad_media" class="w-100" :src="record.ad_media" :alt="record.title" />
                    </template>
                </div>

                <div class="modal-footer px-0 mt-1">
                    <b-button @click="saveBtn" :disabled="loading || loadingMedia" variant="primary">
                        <b-spinner v-if="loading" small />
                        Add
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
    BFormCheckbox,
    BEmbed,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../../Helpers';
import Uploader from "../awsImageUpload.js";

export default {
    components: {
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        BFormCheckbox,
        ToastificationContent,
        vSelect,
        BEmbed,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {
                // default_status: 0,
            },
            addTypes: [
                {
                    label: 'Video',
                    name: 'video',
                },
                {
                    label: 'Image',
                    name: 'image',
                },
            ],
            loadingMedia: false,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
    },
    methods: {
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
        },
        async saveBtn() {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.loading = true;
            try {
                const resp = await axios.post(process.env.VUE_APP_API_URL + "/ads-management/add",
                    this.record,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                this.alertMessageToast = resp.data.message;
                this.showToast("success");
                this.$emit('emitStatusAdded');
                this.$bvModal.hide("add-ads-management-modal");
                this.loading = false;
            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.ads_account_id) {
                this.errorMsg = "Advertiser field is required."
                return;
            }
            if (!this.record.title) {
                this.errorMsg = "Title field is required."
                return;
            }
            if (!this.record.ad_type) {
                this.errorMsg = "Ad type field is required."
                return;
            }
            if (!this.record.ad_media) {
                this.errorMsg = this.record.ad_type=='video'?"Select video.":"Select image."
                return;
            }
            return "ok";
        },
        fileNameAndExt(str) {
            var file = str.split("/").pop();
            return [
                file.substr(0, file.lastIndexOf(".")),
                file.substr(file.lastIndexOf(".") + 1, file.length),
            ];
        },
        videoUpload(data) {
            this.loadingMedia = true;
            if (data.target.files && data.target.files[0]) {
                // this.record.ad_media =
                // "https://zape02.s3.ap-southeast-1.amazonaws.com/0.5286847033473350.93913359917665160.43434108423423257.gif";
                const videoName =
                Math.random().toString() +
                Math.random().toString() +
                Math.random().toString();
                let fileName = this.fileNameAndExt(data.target.files[0]["name"]);
                // const S3Client = new S3(config);
                let payload = {
                    contentType: fileName[1],
                    fileName: videoName,
                    file: data.target.files[0],
                };
                new Uploader().uploadImage(payload).then((response) => {
                    // console.log("response.Location", response.Location);
                    this.loadingMedia = false;
                    this.record.ad_media = response.Location;
                    // console.log("res", response);
                });
                // S3Client.uploadFile(data.target.files[0], videoName)
                //   .then((data) => {
                //     this.record.ad_media = data.location;
                //   })
                //   .catch((err) => console.error(err));
            }
        },
        getFileExtension(mimeType) {
            const mimeToExtension = {
                'image/jpeg': 'jpg',
                'image/png': 'png',
                'image/gif': 'gif',
                'application/json': 'json',
                '.json': 'json',
            };
            return mimeToExtension[mimeType] || 'png';
        },
        async ImageRendererItems(event) {
            this.loadingMedia = true;
            const file = event.target.files[0];
            if (file) {
                const fileType = file.type;
                const fileExtension = this.getFileExtension(fileType);
                try {
                    const response = await axios.post(process.env.VUE_APP_API_URL + '/s3/generate-presigned-url', {
                        type: file.type,
                        ext: fileExtension
                    }, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                    const { url, key } = response.data;
                    const extention = fileExtension == 'json' ? 'lottie' : fileExtension
                    await this.uploadFile(file, url, key, extention);
                } catch (error) {
                    console.error('Error getting pre-signed URL:', error);
                }
            }
            event.target.value = null;
        },
        async uploadFile(file, url, key, ext) {
            try {
                const response = await axios.put(url, file, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': file.type,
                    },
                });

                if (response.status !== 200) {
                    console.error('Error uploading file:', response);
                }
                let s3Url = 'https://zape02.s3.ap-southeast-1.amazonaws.com/' + key;
                console.log('=s3Url, file.name, ext=', s3Url, file.name, ext);
                this.record.ad_media = s3Url;
                this.loadingMedia = false;
            } catch (error) {
                this.loadingMedia = false;
                console.error('Error uploading file:', error);
            }
        },
    },
    props: [
        "manage",
        "get_ads_account",
    ],
};
</script>
